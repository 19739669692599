import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isGroup, notDirectControls } from "../../constants";
import Header from "../Header";
import Controls from "./sections/Controls";
import Groups from "./sections/Groups";
import Lists from "./sections/Lists";

const Editor = ({ fields, title }) => {
	const { t } = useTranslation();
	const editor = t('editor', { returnObjects: true });
	const buttons = t('buttons', { returnObjects: true });

	const controls = useMemo(() => {
		return fields.filter(f => !notDirectControls.includes(f.renderer));
	})

	const lists = useMemo(() => {
		return fields.filter(f => f.renderer === 'List');
	})

	const groups = useMemo(() => {
		return fields.filter(f => isGroup.includes(f.renderer));
	})

	const sections = [
		{
			icon: "TextField",
			title : editor.fields,
			fields: controls,
			Component: Controls
		},
		{
			icon: "NumberedList",
			title : editor.lists,
			fields: lists,
			Component: Lists
		},
		{
			icon: "RowsGroup",
			title : editor.groups,
			fields: groups,
			Component: Groups
		},
	]

	const [hideDialog, toggleHideDialog] = useState(true);

	const modalProps = {
		titleAriaId: 'InsertConditionBox',
		subtitleAriaId: 'InsertConditionBox',
		isBlocking: false,
	};
	
	const [conditionScope, setConditionScope] = useState(null);
	const [conditionEquals, setConditionEquals] = useState(null);
	const [conditionEqualsErr, setConditionEqualsErr] = useState(false);
	const [conditionShow, setConditionShow] = useState('');
	const [conditionElseShow, setConditionElseShow] = useState('');

	const popConditionBox = (scope) => {
		setConditionScope(scope);
		toggleHideDialog(false);
	}

	const closeConditionDialog = () => {
		setConditionScope(null);
		setConditionEquals(null);
		setConditionEqualsErr(false);
		setConditionShow('');
		setConditionElseShow('');
		toggleHideDialog(true)
	}

	const insertCondition = (scope, equals, show, elseShow) => {
		// {d.qualifica:ifEQ(Ordinario):show(x):elseShow()}
		return Word.run(async (context) => {
			/**
			 * Insert your Word code here
			 */

			// insert a paragraph at the end of the document.
			const range = context.document.getSelection()
			range.insertText(`{d.${scope}:ifEQ(${equals}):show(${show}):elseShow(${elseShow})}`, Word.InsertLocation.end);

			await context.sync();
		});
	}

	const onFieldClick = (scope) => {
		return Word.run(async (context) => {
			/**
			 * Insert your Word code here
			 */

			// insert a paragraph at the end of the document.
			const range = context.document.getSelection()
			range.insertText(`{d.${scope}}`, Word.InsertLocation.end);

			await context.sync();
		});
	}

	const dialogContentProps = {
		type: DialogType.normal,
		title: editor.dialog.insertCondition,
		closeButtonAriaLabel: 'Close',
		subText: editor.dialog.insertConditionSubText,
	  };
	  

	return <div className="ms-welcome h-full w-full">
		<Header logo={require("./../../../../assets/applynow-80.png")} message="Editor" variant="compact" />
		<div className="flex flex-col justify-center items-center pt-3 space-y-3 overflow-auto">
			<div className="ms-fontSize-20">{title}</div>
			{sections.map(({icon, title, fields, Component}) => (
			<React.Fragment key={title}>
				<div className="ms-fontSize-18 font-bold w-full px-2 flex flex-row space-x-2">
					<Icon iconName={icon}></Icon>
					<div className="ms-fontSize-16 ">{title}</div>
				</div>
				<div className="flex flex-row flex-wrap w-full">
					<Component fields={fields} onFieldClick={onFieldClick} popConditionBox={popConditionBox} />
				</div>
			</React.Fragment>
			))}
		</div>
		<Dialog
			hidden={hideDialog}
			onDismiss={() => closeConditionDialog()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<p className="ms-font-m mr-4 ml-4 font-bold">{editor.dialog.when} </p>
			<p className="ms-font-m mr-4 ml-4">
				{conditionScope}
			</p>
			<TextField
				className="w-full pl-4 pr-4"
				label={editor.dialog.fields.equals}
				errorMessage={conditionEqualsErr ? editor.errors.fieldRequired : undefined}
				placeholder={editor.dialog.fields.insertValue}
				value={conditionEquals}
				onChange={(_, value) => setConditionEquals(value)}
				required
			/>
			<TextField
				className="w-full pl-4 pr-4"
				label={editor.dialog.fields.show}
				placeholder={editor.dialog.fields.insertValue}
				value={conditionShow}
				onChange={(_, value) => setConditionShow(value)}
			/>
			<TextField
				className="w-full pl-4 pr-4"
				label={editor.dialog.fields.elseShow}
				placeholder={editor.dialog.fields.insertValue}
				value={conditionElseShow}
				onChange={(_, value) => setConditionElseShow(value)}
			/>
			<DialogFooter>
				<PrimaryButton 
					className="text-black"
					onClick={() => {
						if (conditionEquals){
							insertCondition(conditionScope, conditionEquals, conditionShow, conditionElseShow); 
							closeConditionDialog();
						} else {
							setConditionEqualsErr(true);
						}
						}} 
					text={buttons.confirm} 
				/>
				<DefaultButton onClick={() => closeConditionDialog()} text={buttons.close} />
			</DialogFooter>
		</Dialog>
	</div>
}

export default Editor;