import * as React from "react";
import { DefaultButton, TextField } from "@fluentui/react";
import Header from "./components/Header";
import HeroList from "./components/HeroList";
import Progress from "./components/Progress";
import { useState } from "react";
import FieldsLoader from "./components/FieldsLoader";
import { useTranslation } from "react-i18next";
import Editor from "./components/Editor";

/* global Word, require */

const App = (props) => {
  const { t } = useTranslation();
  const home = t('home', { returnObjects: true });
  const header = t('header', { returnObjects: true });
  const buttons = t('buttons', { returnObjects: true });
  const { fields, steps, errors } = home;

  const [fail, setFail] = useState(false);

  const [code, setCode] = useState(null);
  const [moduleCode, setModuleCode] = useState();
  const [moduleTitle, setModuleTitle] = useState();
  const [moduleFields, setModuleFields] = useState();

  const listItems = React.useMemo(() => [
    {
      icon: "Ribbon",
      primaryText: steps.copy,
    },
    {
      icon: "Unlock",
      primaryText: steps.paste,
    },
  ]);

  const onStart = async () => {
    setModuleCode(code);
    // MOCK HAS TO BE DISABLED IN PRODUCTION
    if (process.env.MOCK_MODULE) {
      const json = require("../mock/module.json");
      setModuleTitle(json.title);
      setModuleFields(json.fields);
    } else {
      try {
        const rawResponse = await fetch(`${process.env.BACKEND_TEMPLATE_ENDPOINT}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ id: code })
        });
        const { Data } = await rawResponse.json();
        setModuleTitle(Data.title);
        setModuleFields(Data.fields);
        setFail(false);
      } catch (err) {
        console.log(err)
        setModuleCode(null);
        setFail(true);
      }
    }
  }

  const { title, isOfficeInitialized } = props;

  if (!isOfficeInitialized) {
    return (
      <Progress
        title={title}
        logo={require("./../../assets/applynow-80.png")}
        message="Please sideload this addin to see app body."
      />
    );
  }

  if (moduleFields) {
    return <Editor title={moduleTitle} fields={moduleFields} />
  }

  if (moduleCode) {
    return <FieldsLoader moduleCode={moduleCode} setModuleFields={setModuleFields} />
  }

  return (
    <div className="ms-welcome">
      <Header
        logo={require("./../../assets/applynow-80.png")}
        title={props.title}
        message={header.welcome} />
      <HeroList message={home.startText} items={listItems}>
        <p className="ms-font-l mb-4">
          {home.finalizeText} <b>{buttons.start}</b>.
        </p>
        <TextField
          className="w-full pl-8 pr-8"
          label={fields.templateCode.label}
          placeholder={fields.templateCode.placeholder}
          errorMessage={fail ? errors.invalidCode : undefined}
          value={code}
          onChange={(_, value) => setCode(value)}
          required
        />
        <DefaultButton
          className="mt-4 ms-welcome__action"
          iconprops={{ iconName: "ChevronRight" }}
          onClick={async () => await onStart()}>
          {buttons.start}
        </DefaultButton>
      </HeroList>
    </div>
  );

}

export default App;