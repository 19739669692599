export default {
  header: {
    welcome: "Benvenuto",
    init: "Inizializzazione"
  },
  home: {
    steps: {
      copy: 'Dalla pagina di modifica del modulo copiare il "Codice Template"',
      paste: "Incollare il codice nel box sottostante"
    },
    fields: {
      templateCode: {
        label: "Codice Template",
        placeholder: "Incolla codice template"
      }
    },
    errors: {
      invalidCode: "Il codice inserito non è corretto o applynow non è raggiungibile",
    },
    startText: "Inizia a creare un template!",
    finalizeText: "Incolla il codice e clicca"
  },
  editor: {
    fields: "Campi",
    lists: "Liste",
    groups: "Gruppi",
    errors: {
      fieldRequired: 'Campo obbligatorio',
    },
    dialog: {
      when: "Quando",
      insertCondition: "Inserisci condizione",
      insertConditionSubText: "Configura la condizione",
      fields: {
        equals: 'È uguale a',
        show: 'Mostra',
        elseShow: 'Altrimenti mostra',
        insertValue: 'Inserisci valore',
      },
    },
  },
  buttons: {
    start: 'Inizia',
    confirm: 'Conferma',
    close: 'Close',
    insertCondition: 'Inserisci condizione',
  }
};
