import { DefaultButton } from "@fluentui/react";
import { t } from "i18next";
import * as React from "react";

const Controls = ({fields, onFieldClick, popConditionBox}) => {
	const buttons = t('buttons', { returnObjects: true });

	const menuProps = (scope) => ({
		items: [
		  {
			key: `insertCondition-${scope}`,
			text: buttons.insertCondition,
			iconProps: { iconName: 'Split' },
			onClick: () => popConditionBox(scope)
		  },
		],
	  });

	return fields.map((field, index) => {
		return <div key={`ctr${field.scope}-${field.index}`} className="p-1">
			<DefaultButton 
				className="flex items-center" 
				split
				menuProps={menuProps(field.scope)}
				onClick={() => onFieldClick(field.scope)}
			>
				{field.label}
			</DefaultButton>
		</div>
	})
}

export default Controls;
