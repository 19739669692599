import * as React from "react";
import PropTypes from "prop-types";
import { useMemo } from "react";

const Header = ({ title, logo, message, variant }) => {

  const props = useMemo(() => {
    let logoDim, applynowBigSize, flex;
    if (variant === 'compact') {
      logoDim = 50;
      applynowBigSize = true;
      flex = "row";
    } else {
      logoDim = 80;
    }

    return {
      logoDim,
      applynowBigSize,
      flex
    }
  })

  return (
    <section className={`ms-welcome__header ms-bgColor-neutralLighter ms-u-fadeIn500 flex ${props.flex ? 'flex-row' : 'flex-col'} items-center justify-center`}>
      <div>
        <img width={props.logoDim} height={props.logoDim} src={logo} alt={title} title={title} />
      </div>
      <div className="flex flex-row">
        <div className={`leading-none font-bold logo-text ${props.applynowBigSize ? 'text-[28px]' : 'text-xl'}`} color="inherit">
          APPLY
        </div>
        <div className={`leading-none ml-[1px] font-thin logo-text ${props.applynowBigSize ? 'text-[28px]' : 'text-xl'}`} color="inherit">
          NOW
        </div>
      </div>
      <h1 className={`ms-fontWeight-light ms-fontColor-neutralPrimary ${props.applynowBigSize ? 'ms-fontSize-20' : 'ms-fontSize-32 mt-2'}`}>{message}</h1>
    </section>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.string,
};

export default Header;