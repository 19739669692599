import { DefaultButton } from "@fluentui/react";
import * as React from "react";

const Lists = ({fields, onFieldClick, variant, popConditionBox}) => {
	
	return fields.map((field, index) => {
		return <div key={`f${field.scope + index}`} className={`flex flex-col w-full ${index !== 0 ? 'pt-2' : ''}`}>
			<div className="p-1 w-full">
				<DefaultButton
					iconProps={{ iconName: 'GroupedDescending' }}
					className="flex items-center w-full justify-center border-0 ms-fontSize-12" 
					disabled>
					{`Inizio "${field.label}"`}
				</DefaultButton>
			</div>
			<div className="flex flex-row flex-wrap">
				{field.childs.map(child => {
					const menuProps = {
						items: [
							{
								key: `${child.scope} i`,
								text: `${child.label} i`,
								onClick: () => onFieldClick(`${field.scope}[i].${child.scope}`)
							},
							{
								key: `${child.scope} i+1`,
								text: `${child.label} i+1`,
								onClick: () => onFieldClick(`${field.scope}[i+1].${child.scope}`)
							},
						],
					};

					return <div key={`lc${field.scope}${child.scope}${index}`} className="p-1">
						<DefaultButton
							text={child.label}
							menuProps={menuProps}
							allowDisabledFocus
						/>
					</div>
				})}
			</div>
			<div className="p-1 w-full">
				<DefaultButton
					iconProps={{ iconName: 'GroupedAscending' }}
					className="flex items-center w-full justify-center border-0 ms-fontSize-12"
					disabled
				>
					{`Fine "${field.label}"`}
				</DefaultButton>
			</div>
		</div>

	})
}

export default Lists;
