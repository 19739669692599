import { DefaultButton } from "@fluentui/react";
import * as React from "react";
import { useMemo } from "react";
import { notDirectControls } from "../../../constants";
import Controls from "./Controls";
import Lists from "./Lists";

const Groups = ({fields, onFieldClick, popConditionBox}) => {

	return fields.map((field, index) => {
				
		const controls = useMemo(() => {
			return field.childs?.filter(f => !notDirectControls.includes(f.renderer));
		})

		const lists = useMemo(() => {
			return field.childs?.filter(f => f.renderer === 'List');
		})

		return <div key={`g${field.label + index}`} className={`flex flex-col w-full ${index !== 0 ? 'pt-2' : ''}`}>
			<div className="p-1 w-full">
				<DefaultButton
					className="flex items-center w-full justify-center border-0"
					disabled>
					{`${field.label}`}
				</DefaultButton>
			</div>
			<Controls fields={controls} onFieldClick={onFieldClick} popConditionBox={popConditionBox}/>
			<Lists fields={lists} />
		</div>

	})
}

export default Groups;
