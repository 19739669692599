import * as React from "react";
import { DefaultButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import Header from "../Header";
import HeroList from "../HeroList";
import { useTranslation } from "react-i18next";

const FieldsLoader = (props) => {
	const { t } = useTranslation();
	const header = t('header', { returnObjects: true });
	return (
		<div className="ms-welcome h-full">
			<Header logo={require("./../../../../assets/applynow-80.png")} message={header.init} />
			<div className="h-1/3" />
			<Spinner size={SpinnerSize.large} label="Caricamento campi" />
		</div >
	);
}

export default FieldsLoader;